@import '../index.css';

.img-thankyou{
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    width: 25%;
}
.button-div{
    display: flex;
    justify-content: center;
}
.thankyou-btn{
    background-color: #1aa260;
    font-size: x-large;
}
.title-thank{
    font-size: 40px;
}
@media only screen  and (max-width: 1199px){
    .img-thankyou{
        width: 50%;
    }
    .thankyou-btn{
        width:15rem;
        font-size:18px;
    }
    .title-thank{
        font-size: 24px;
    }
}