@import "../index.css";

.main-div {
  margin-top: 8rem;
}
@media only screen  and (max-width: 1199px){
  .main-div {
    margin-top: 4rem;
  }
  .desk{
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .mobile{
    display: none ;
  }
}
.arrow {
  font-size: 50px !important;
}
.search-icon {
  position: absolute;
  margin-right: 23rem;
  margin-top: 2.5rem;
}
.close-arrow {
  font-size: 24px;
}
.section {
  margin-left: auto;
  margin-right: auto;
}
.drop {
  width: 300px;
  margin-left: 2rem;
}
@media screen and (max-width: 400px) {
  .input-box {
    width: 16rem;
  }
  .arrow {
    font-variant-caps: unicase;
  }
  .search-icon {
    position: absolute;
    margin-right: 11.02rem;
    margin-top: 40px;
  }
  .font-9p {
    font-size: 15px;
  }
  .close-arrow {
    font-size: 24px;
  }
}

@media screen and (max-width: 320px) {
  .input-box {
    width: 11rem;
  }
  .arrow {
    font-variant-caps: unicase;
  }
}
@media only screen and (max-width: 600px) {
  .arrow {
    font-variant-caps: unicase !important;
    margin-top: 40px !important;
  }
  .close-arrow {
    font-size: 24px !important;
  }
  .drop {
    margin-left: 20px;
  }
}
@media screen and (min-width: 550px) {
  .input-box {
    width: 20rem;
  }
}
@media screen and (max-width: 400px) {
  .drop {
    width: 15rem;
  }
}
@media screen and (max-width: 320px) {
  .drop {
    width: 11rem;
  }
}
.position-fi{
  margin-top: 13rem;
}