@import '../index.css';

.paginationItem.active {
    color: #0A5E72;
    pointer-events: none;
    font-weight: bold;
}
.next, .prev {
    color: #0A5E72;
}
.prev.disabled,
.next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  }