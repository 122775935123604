@import '../index.css';

  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    background: #fff;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    overflow: hidden;
    border: 1px solid;
    border-radius: 2px
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 18px;
    background: #0A5E72;
    box-shadow: -1010px 0 0 1000px #84CFDC;
    border-radius: 50%; 
    cursor: pointer;
    border: 0;
  }
  
  .v, .v2, .v3, .v4, .v5 {
    border-left: 1px solid;
    height: 15px;
    margin-top: -5px;
  }
  .v {
    margin-left: 2%
  }
  .v2 {
    margin-left: 28%;
  }

  .v3 {
    margin-left: 51%;
  }

  .vn2 {
    margin-left: 24%;
  }

  .v4 {
    margin-left: 72%;
  }

  .three {
    margin-left: 48%;
  }
  .four {
    margin-left: 68%;
  }

  .v5 {
    margin-left: 99%;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: 10px;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 280px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -1rem;
  }
  
  .dropdown-content span {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content span:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  .dropdown-content div:hover {
    background-color: #ddd;
    cursor: pointer;
  }

  .dropdown-content .keyboard {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {display: block;}
  
  
  @media only screen and (min-width: 320px){
    .lowest_rating_1 {
      margin-left: -40px;
    }
    .lowest_rating_2 {
      margin-left: -40px;
    }
    .lowest_rating_3 {
      margin-left: -40px;
    }
    .lowest_rating_4 {
      margin-left: -40px;
    }
    .highest_rating_1 {
      margin-right: -40px;
    }
    .highest_rating_2 {
      margin-right: -40px;
    }
    .highest_rating_3 {
      margin-right: -40px;
    }
    .highest_rating_4 {
      margin-right: -40px;
    }
  }

  @media only screen and (min-width: 1024px){

    // .lowest_rating_3 {
    //   margin-left: -35px;
    // }
    .highest_rating_1 {
      margin-right: -45px;
    }
    .highest_rating_2 {
      margin-right: -40px;
    }
    .highest_rating_3 {
      margin-right: -45px;
    }
    .highest_rating_4 {
      margin-right: -45px;
    }
  }
  .review-button{
    height: 7rem;
   }
   .start-review{
     background-color: #1aa260;
   }