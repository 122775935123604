@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

:root {
  --color-primary: #5c6ac4;
  --color-secondary: #ecc94b;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato-Regular'), url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Book';
  src: local('Avenir-Book'), url(./fonts/Avenir-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: local('Avenir-Heavy'), url(./fonts/Avenir-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'ITC-Avant-Garde-Gothic-Bold';
  src: local('ITC-Avant-Garde-Gothic-Bold'), url(./fonts/ITC-Avant-Garde-Gothic-Bold.otf) format('opentype');
}
.lg-font-25p{
  font-size: 30px;
}

.font-lato {
  font-family: Lato;
}

.font-poppins {
  font-family: Poppins;
}

.font-lato-regular {
  font-family: Lato-Regular;
}

.font-lato-medium {
  font-family: Lato;
  font-weight: 400;
}

.font-avenir {
  font-family: Avenir-Book;
}

.font-avenir-heavy {
  font-family: Avenir-Heavy;
}

.font-itc {
  font-family: ITC-Avant-Garde-Gothic-Bold;
}

input::placeholder {
  font-family: Avenir-Book;
  font-size: 14px;
}
textarea::placeholder {
  font-family: Avenir-Book;
  font-size: 14px;
}
label {
 font-family: ITC-Avant-Garde-Gothic-Bold;
}
.letter-spacing-30p {
  letter-spacing: 30px;
}

.emoji-mart-emoji {
  margin: 0 10px !important;
}
.ml-n-10p {
 margin-left: -10px;
}
.br-10p {
  border-radius: 10px !important;
}
.color-grey {
  color: grey;
}

@media only screen and (max-width: 576px){
  .xs_lowest_rating {
    margin-left: -50% !important;
  }
  .xs_highest_rating {
    margin-right: -50% !important;
  }
  .grid-block {
    display: block;
  }
  .xs-mlr-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xs-mx {
     margin:0 1rem;
  }
  .xs-mx-2 {
    margin:0 2rem;
 }
 .xs-mx-4 {
  margin:0 4rem;
}
  .xs-ml {
    margin-left: 1rem;
  }
  .xs-mr {
    margin-right: 1rem;
  }
  .xs-ml-2 {
    margin-left: 2rem;
  }
  .xs-mr-2 {
    margin-right: 2rem;
  }
  .xs-mt-0_5 {
    margin-top: 0.5rem;
  }
  .w-unset {
    width: unset;
  }
  .width-12px {
    width: 12px;
  }
  .width-80px {
    width: 80px;
  }
  .width-304px {
    width: 304px;
  }
  .width-2 {
    width: 2.5rem;
  }
  .width-3 {
    width: 3rem;
  }
  .width-4_6 {
    width: 4.6rem;
  }
  .width-5 {
    width: 5rem;
  }
  .width-7_75 {
   width: 7.75rem;
  }
  .width-8 {
    width: 8rem;
  }
  .width-9 {
    width: 9rem;
  }
  .width-10 {
    width: 10rem;
  }
  .width-11 {
    width: 11rem;
  }
  .width-12 {
    width: 12rem;
  }
  .width-15 {
    width: 15rem;
  }
  .width-16 {
    width: 16rem;
  }
  .width-17 {
    width: 17rem;
  }
  .width-18 {
    width: 18rem;
  }
  .width-19 {
    width: 19rem;
  }
  .width-70p {
    width: 70%;
  }
  .width-75p {
    width: 75%;
  }
  .width-80p {
    width: 80%;
  }
  .m-width-50p {
    max-width: 50%;
  }
  .justify-unset {
    justify-content: unset;
  }
  .font-9p {
    font-size: 9px;
  }
  .font-10p {
    font-size: 10px;
  }
  .font-14p {
    font-size: 14px;
  }
  .font-18p {
    font-size: 18px;
  }
  .font-20p {
    font-size: 20px;
  }
  .font-2_25r {
    font-size: 2.25rem;
  }
  .font-2_5r {
    font-size: 2.5rem;
  }
  .font-2_75r {
    font-size: 2.75rem;
  }
  .desktop {
    display: none
  }
  .xs-social {
    font-size: 2rem;
    color: #0A5E72;
  }
  .green-c {
    color: #0A5E72;
  }
  .xs-pt-unset {
    padding-top: unset;
  }
  .xs-pt-1 {
    padding-top: 1rem;
  }
  .xs-pt-3 {
    padding-top: 3rem;
  }
  .xs-pt-64p {
    padding-top: 64px;
  }
  .xs-pb-2 {
    padding-bottom: 2rem;
  }
  .xs-pb-3 {
    padding-bottom: 3rem;
  }
  .xs-pb-8 {
    padding-bottom: 8rem;
  }
  .xs-py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media only screen  and (max-width: 1199px){
  .margin-bottom{
    padding-bottom: 7rem;
  }
}
 .xs-h-285p {
    height: 285px;
 }
 .xs-h-28p {
     height: 28px;
 }
 .xs-mb-1 {
   margin-bottom: 1rem;
 }
}

@media only screen and (min-width: 768px){
  .md-width-26 {
    width: 26rem;
  }
  .mt-n-10px {
    margin-top: -10px;
  }
}

@media only screen and (min-width: 992px){

  .lg-font-10p{
    font-size: 10px;
  }
  .lg-font-12p{
    font-size: 12px;
  }
  .lg-font-13p{
    font-size: 13px;
  }
  .lg-font-15p{
    font-size: 15px;
  }
  .lg-font-16p{
    font-size: 16px;
  }
  .lg-font-18p{
    font-size: 18px;
  }
  .lg-font-21p{
    font-size: 21px;
  }
  .lg-font-22p{
    font-size: 22px;
  }
  .lg-font-25p{
    font-size: 35px;
  }
  .lg-font-28p{
    font-size: 28px;
  }
  .lg-font-35p{
    font-size: 35px;
  }
  .lg-font-40p{
    font-size: 40px;
  }
  .lg-font-100p{
    font-size: 100px;
  }

  .lg-height{
    height: 28.5rem;
  }

  .lg-w-28 {
    width: 28rem !important;
  }
  .lg-w-34 {
    width: 34rem !important;
  }
}
.mh-100{
  min-height: 100vh;
}
.fd-col{
  flex-direction: column;
}
.justify-space-between{
  justify-content: space-between;
}







