@import '../index.css';

.text-center{
    text-align: center;
}
.q-margin{
    margin: 30px 0px 15px 0px;
}
.ml-15{
    margin-left: 15px;
}
.mt-20{
    margin-top: 20px;
}
.Question-bold{
  font-weight: bold;
}

.btn-next{
    background: #DB7825;
    width: 110px;
    height: 45px;
    margin-left: 5px;
    color: white;
    font-weight: bold;
}
.btn-back{
  width: 110px;
  height: 45px;
  text-align: left;
  color: #DB7825;
  font-weight: bold;
}

.btn-disable{
  background: #e9b081 !important;
}
.flex-end{
    justify-content: flex-end;
    align-items: flex-end;
}

.btn-dis{
  background:#DB7825 !important;
}

@media only screen and (min-width: 1200px) {
    .mobile{
      display: none ;
    }
    .box-w{
      width: 50%;
    }
    .btn-align{
      justify-content: start;
    }
    .mtb-3{
      margin: -8rem 0rem -8rem -9rem ;
      padding: 10%;
    }
   }

@media only screen and (max-width: 1700px)and(min-width:1201px) {
  .mobile{
    display: none ;
  }
  .box-w{
    width: 50%;
  }
  .btn-align{
    justify-content: start;
  }
  .mtb-3{
    margin: -4rem 0rem -4rem -4rem;
    padding: 9% ;

}
 
}
  @media only screen  and (max-width: 1199px){
    .desk{
      display: none;
    }
    .position-rel{
      position: relative;
    }
    .box-w{
      width: 100%;
    }
    .border-rad{
      border-radius: 15px 0px 15px 0px;
    }
    .btn-align{
      justify-content: space-between;
    }
    
    .check-bg{
      width: 35px;
      position: absolute;
      top: 13px;
      left: 8px;
      height: 30px;
        border-radius: 50%;
        background-color: rgb(243, 144, 14);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
    }
    .check-img{
       height: 25px;
      width: 20px;
    }
    .check-none{
      display: none;
    }
    .mtb-3{
      margin: 2rem 0rem 2rem 0rem;
      place-content: space-around;
  }
  .custom-select{
    height: 2rem !important;
    width: 6rem !important;
  }
  .multiple-input{
    height: 20px !important;
    width: 20px !important;
  }
  }

.selected-option{
  text-indent: 40px;
  line-height:20px;
  border: none;
  background-color: #DB7825;
}  

.slider_parent{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mtb-2{
  margin: 2rem 0rem 2rem 0rem;
}
.mtb-1{
  margin: 1rem 0rem 1rem 0rem;
}

.mar-top-3{
  margin-top: 3rem;
}
h1{
  text-align: center;
  font-size: 3rem;
  padding-bottom: 2rem;
  color:rgba(8, 80, 68, 0.767);
}
.form-padd{
  padding-bottom: 0rem;
  padding-top: 0rem;
}
.gap-4{
    gap: 4rem;
}
.form-head{
  padding-bottom: 30px;
}
.drop-select{
  height: 3rem;
  width: 14rem;
  background-color: #bce6ee;
}
.slider-margin{
  margin-top: 3rem;
  margin-left: 0px;
}
.multiple-input{
  height: 25px;
  width: 25px;
}
.multiple-label{
  font-size: 18px;
}
.radio-size{
  height: 20px;
  width: 20px;
}
.radio-text{
  margin-left: 5px;
  font-size: 18px;
}
.position-11{
  display: none;
}